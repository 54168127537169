import { Typography } from '@mui/material';

import { RestrictionLayout } from '@one-platform/components/restrictions/restriction-layout';

export function InvitationExpired() {
  return (
    <RestrictionLayout>
      <Typography variant="subtitle2" textAlign="center" mb={3}>
        Hi there,
        <br />
        Looks like your invitation link has expired.
      </Typography>

      <Typography variant="body2" textAlign="center">
        To request access, contact the account admin.
      </Typography>
    </RestrictionLayout>
  );
}
