import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  SxProps,
  styled,
  svgIconClasses,
} from '@mui/material';
import cls from 'classnames';
import { forwardRef } from 'react';

import { UtilityProps } from '../../utils/prop';
import { Spinner } from '../Spinner';

export enum ButtonVariant {
  CAUTIOUS = 'cautious',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST_WHITE = 'ghost_white',
  GHOST_SOLAR = 'ghost_solar',
  GHOST_EARTH = 'ghost_earth',
}

export enum ButtonSize {
  SUPER = 'super',
  LARGE = 'large',
  REGULAR = 'regular',
  COMPACT = 'compact',
}

type BaseProps = {
  /** The variant of Button */
  variant?: ButtonVariant | `${ButtonVariant}`;

  /** The size of Button */
  size?: ButtonSize | `${ButtonSize}`;

  /** If `true`, Button will enter loading state */
  loading?: boolean;
} & UtilityProps;

type Props = BaseProps & Omit<MuiButtonProps, keyof BaseProps | 'color' | 'endIcon'>;

const classes = {
  cautious: 'Button--cautious',
  primary: 'Button--primary',
  secondary: 'Button--secondary',
  ghost_white: 'Button--ghost-white',
  ghost_earth: 'Button--ghost-earth',
  ghost_solar: 'Button--ghost-solar',

  super: 'Button--super',
  large: 'Button--large',
  regular: 'Button--regular',
  compact: 'Button--compact',

  loading: 'Button--loading',

  spinner: 'Button__spinner',
  children: 'Button__children',
};

/** This component inherits [MUI Button's API](https://mui.com/material-ui/api/button/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-button-props--documentation)
 */
const Button = styled(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        children,
        loading = false,
        startIcon: startIconProp,
        size = ButtonSize.REGULAR,
        variant = ButtonVariant.PRIMARY,
        ...props
      }: Props,
      ref,
    ) => {
      const startIcon = loading ? undefined : startIconProp;

      return (
        <MuiButton
          {...props}
          variant={variant}
          startIcon={startIcon}
          ref={ref}
          className={cls([
            props.className,
            classes[variant],
            classes[size],
            loading && classes.loading,
          ])}
        >
          {loading && (
            <Spinner
              className={classes.spinner}
              thickness={2}
              size={size === 'compact' ? 13 : 20}
            />
          )}

          <Box data-test-id="button-children" className={classes.children}>
            {children}
          </Box>
        </MuiButton>
      );
    },
  ),
)(({ theme: { spacing } }) => {
  return {
    '&': {
      height: '36px',

      [`&.${classes.super}`]: {
        minWidth: '220px',
      },

      [`&.${classes.large}`]: {
        minWidth: '160px',
      },

      [`&.${classes.regular}`]: {
        minWidth: '100px',
      },

      [`&.${classes.compact}`]: {
        width: 'max-content',
        minWidth: 'unset',
        height: '20px',
        padding: `0px ${spacing(3)} !important`,
        borderRadius: '10px',

        fontSize: '12px',
        lineHeight: '16px',

        [`.${svgIconClasses.root}`]: {
          fontSize: '13px',
        },
      },

      /** ---- LOADING STYLE ---- */

      gridTemplateAreas: 'stack',

      [`.${classes.spinner}`]: {
        gridArea: 'stack',
        visibility: 'hidden',
      },

      [`.${classes.children}`]: {
        gridArea: 'stack',
      },

      [`&.${classes.loading}`]: {
        display: 'grid',

        [`.${classes.spinner}`]: {
          visibility: 'visible',
          marginInline: 'auto',
        },

        [`.${classes.children}`]: {
          visibility: 'hidden',
        },
      },
    },
  } satisfies SxProps;
});

Button.displayName = 'Button';

export { Button, classes as buttonClasses };
export type ButtonProps = Props;
