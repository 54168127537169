import { ChevronRightRounded } from '@mui/icons-material';
import {
  AutocompleteProps,
  AutocompleteValue,
  Box,
  ChipTypeMap,
  Autocomplete as MuiAutocomplete,
  Stack,
  SxProps,
  Theme,
  Typography,
  autocompleteClasses,
  chipClasses,
  inputBaseClasses,
  menuItemClasses,
  paperClasses,
  styled,
  svgIconClasses,
  useEventCallback,
  useTheme,
} from '@mui/material';
import { merge } from 'lodash';
import { Fragment, Key, KeyboardEvent, ReactNode, useLayoutEffect, useRef } from 'react';

import '@interface/sdk/types/reset';

import { useInputValue } from '../../hooks/useInputValue';
import { UtilityProps } from '../../utils/prop';
import { InputLozenge } from '../Lozenge/InputLozenge';
import { defaultRenderOptions } from './utils';

const muiFocusedClass = 'Mui-focused';

const BaseAutocomplete = styled(MuiAutocomplete)(
  ({ theme: { spacing } }) =>
    ({
      [`.${inputBaseClasses.root}`]: {
        padding: `${spacing(2, 3)} !important`,
        paddingRight: `${spacing(6)} !important`,

        '& input': {
          padding: '0px !important',
        },

        [`& .${chipClasses.root}`]: {
          display: 'none',
        },
      },

      [`.${autocompleteClasses.popupIndicator}`]: {
        marginRight: '0px',
      },
    }) satisfies SxProps,
);

const autocompletePaperStyles = ({ palette, spacing, shape: { borderRadius } }: Theme) =>
  ({
    [`&.${paperClasses.root}`]: {
      mt: '8px',
      padding: spacing(4, 2),

      boxShadow: 'none',
      border: `1px solid ${palette.$functional.bg_borders.divider}`,
      borderRadius: `${borderRadius}px`,

      '&:empty': {
        mt: '0px',
        padding: '0px',
        border: 'none',
      },

      [`& .${autocompleteClasses.listbox}`]: {
        padding: '0px',
      },

      [`& .${autocompleteClasses.option}`]: {
        color: palette.$text.base,
        fontWeight: '350 !important',
        fontSize: '16px !important',

        '&:active': {
          backgroundColor: `${palette.$functional.bg_borders.pressed} !important`,
        },

        [`&.${autocompleteClasses.focused}`]: {
          backgroundColor: palette.$functional.bg_borders.hovered,
        },

        [`&[aria-selected="true"]`]: {
          backgroundColor: palette.$functional.bg_borders.hovered,

          [`&:not(:hover)`]: {
            backgroundColor: palette.$white,
          },

          [`&.${autocompleteClasses.focused}`]: {
            backgroundColor: palette.$functional.bg_borders.hovered,
          },
        },
      },
    },
  }) satisfies SxProps;

type BaseProps<T> = {
  /** The function to render lozenges in `multiple` mode, will override `getOptionLabel`, `getLozengeOptionLabel` */
  renderLozenge?: (option: T, index: number) => ReactNode;

  /** The function to return label of lozenges in `multiple` mode, will override `getOptionLabel` */
  getLozengeLabel?: (options: T) => string;

  /** Key of option when value is an object */
  optionKey?: T extends { [key in string]: string | number } ? keyof T : never;

  /** Additional keyboard keys beside the default `Enter` to trigger submitting behavior of `Autocomplete` */
  additionalKeysToTriggerFieldSubmit?: (' ' | 'Tab')[];

  /** `true` will highlight the characters that match the input value */
  enableHighlightingOptionMatchedCharacters?: boolean;
} & UtilityProps;

type Props<
  T,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = BaseProps<T> &
  Omit<AutocompleteProps<T, Multiple, true, FreeSolo, ChipComponent>, keyof BaseProps<T>>;

/** This component inherits [MUI Autocomplete's API](https://mui.com/material-ui/api/autocomplete/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-form-select--multiple-select)
 */
function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(props: Props<T, Multiple, FreeSolo, ChipComponent>) {
  const {
    optionKey,
    getLozengeLabel,
    renderLozenge,
    value,
    onChange,
    inputValue,
    onInputChange,
    defaultValue,
    additionalKeysToTriggerFieldSubmit = [],
    enableHighlightingOptionMatchedCharacters = false,
    onKeyDown,
    autoHighlight,
    onOpen,
    ...autoCompleteProps
  } = props;
  const { multiple, disabled } = autoCompleteProps;

  const { handleInternalValueChange, internalValue } = useInputValue({
    value,
    onChange,
    defaultValue,
  });

  const {
    handleInternalValueChange: handleInternalInputValueChange,
    internalValue: internalInputValue,
  } = useInputValue({ value: inputValue, onChange: onInputChange, defaultValue: '' });

  const shouldRenderChips = multiple && Array.isArray(internalValue) && !!internalValue.length;
  const paperRef = useRef<HTMLDivElement>(null);

  const autoHighLightItem = useEventCallback(() => {
    if (!autoHighlight) return;

    const firstItemThatIsNotSelected = paperRef.current?.querySelector(
      `.${menuItemClasses.root}[aria-selected="false"]`,
    );

    if (!firstItemThatIsNotSelected) return;
    paperRef.current?.querySelectorAll(`.${menuItemClasses.root}`).forEach((e) => {
      e.classList.remove(muiFocusedClass);
    });

    firstItemThatIsNotSelected.classList.add(muiFocusedClass);
  });

  useLayoutEffect(() => {
    autoHighLightItem();
  }, [internalValue, props.options]);

  const triggerFieldSubmitBehavior = (e: KeyboardEvent<HTMLDivElement>) => {
    const targetKeys =
      additionalKeysToTriggerFieldSubmit.length === 0
        ? []
        : [...additionalKeysToTriggerFieldSubmit, 'Enter'];

    if (!targetKeys.includes(e.key)) {
      return;
    }

    e.preventDefault();
    let value: unknown;

    if (props.freeSolo) {
      value = internalInputValue;
    } else {
      const itemElement = paperRef.current?.querySelector(
        `li.${menuItemClasses.root}${autoHighlight ? `.${muiFocusedClass}` : ''}[aria-selected="false"]`,
      ) as HTMLLIElement;

      if (!itemElement) return;

      try {
        value = JSON.parse(itemElement.dataset['value'] || '""');
      } catch {
        value = undefined;
      }
    }

    if (!value) return;

    if (Array.isArray(internalValue) && multiple) {
      handleInternalValueChange(
        e,
        [...internalValue, value] as AutocompleteValue<T, Multiple, true, FreeSolo>,
        'selectOption',
      );
    } else {
      handleInternalValueChange(
        e,
        value as AutocompleteValue<T, Multiple, true, FreeSolo>,
        'selectOption',
      );
    }

    handleInternalInputValueChange(e, '', 'reset');
  };

  const handleAutocompleteKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    onKeyDown?.(e);
    triggerFieldSubmitBehavior(e);
  };

  const theme = useTheme();
  const { palette } = theme;

  return (
    <Box>
      {/* @ts-expect-error False Positive */}
      <BaseAutocomplete<T, Multiple, true, FreeSolo, ChipComponent>
        {...merge<
          Partial<AutocompleteProps<T, Multiple, true, FreeSolo, ChipComponent>>,
          AutocompleteProps<T, Multiple, true, FreeSolo, ChipComponent>
        >(
          {
            autoHighlight,
            onKeyDown: handleAutocompleteKeyDown,
            onInputChange: handleInternalInputValueChange,
            inputValue: internalInputValue,
            value: internalValue,
            onChange: handleInternalValueChange,
            disableClearable: true,
            onOpen(...params) {
              onOpen?.(...params);
              setTimeout(() => {
                autoHighLightItem();
              }, 0);
            },

            // To disable rendering tags, MUI is setting `key` by spreading props which is not recommended
            renderTags: (v) => <Fragment key={JSON.stringify(v)} />,

            slotProps: {
              popupIndicator: {
                sx: {
                  rotate: '90deg',
                  color: disabled ? palette.$earth.$200 : undefined,

                  [`.${svgIconClasses.root}`]: {
                    fontSize: '1.5rem',
                  },

                  [`&.${autocompleteClasses.popupIndicatorOpen}`]: {
                    transform: 'none !important',
                  },
                },
              },
              paper: {
                ref: paperRef,
                sx: autocompletePaperStyles,
              },
            },
            renderOption: (optionProps, option, state) => {
              return defaultRenderOptions({
                option,
                optionProps,
                state,
                optionKey,
                additionalKeysToTriggerFieldSubmit,
                enableHighlightingOptionMatchedCharacters,
                freeSolo: props.freeSolo,
                getOptionLabel: props.getOptionLabel,
                theme,
              });
            },
          },
          autoCompleteProps ?? {},
        )}
        popupIcon={<ChevronRightRounded />}
        noOptionsText={
          <Typography variant="button" color={palette.$text.base}>
            {props.noOptionsText ?? 'No options'}
          </Typography>
        }
      />

      {shouldRenderChips && (
        <Stack mt={2} gap={2} direction="row" flexWrap="wrap">
          {internalValue.map((option, index) => {
            if (renderLozenge) return renderLozenge(option, index);

            let key: Key = option;
            if (typeof option === 'object' && optionKey) {
              key = option?.[optionKey] as string | number;
            }

            return (
              <InputLozenge
                key={key}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onDelete={(e: any) => {
                  if (autoCompleteProps.disabled) return;
                  handleInternalValueChange(
                    e,
                    internalValue.filter(
                      (optionValue) => optionValue !== option,
                    ) as AutocompleteValue<T, Multiple, true, FreeSolo>,
                    'removeOption',
                  );
                }}
              >
                {getLozengeLabel?.(option) ?? props?.getOptionLabel?.(option) ?? (option as string)}
              </InputLozenge>
            );
          })}
        </Stack>
      )}
    </Box>
  );
}

export { Autocomplete };
