import { ThemeProvider } from '@mui/material';
import type { ComponentProps } from 'react';

import { Spinner } from '@interface/components/Spinner';
import { DrovaLight } from '@interface/mui-themes/lib/drova';

export const Progress = (props: ComponentProps<typeof Spinner>) => {
  return (
    <ThemeProvider theme={DrovaLight}>
      <Spinner />
    </ThemeProvider>
  );
};
