import {
  Box,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuProps,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { InjectedProps } from 'material-ui-popup-state';
import {
  ReactElement,
  ReactNode,
  forwardRef,
  isValidElement,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as colors from '@interface/colors/lib/common';
import { EXTENDED_PALETTE } from '@interface/mui-themes/lib/drova/color-palette';

const Tick = () => {
  return (
    <Box sx={{ transform: 'translateY(-0.25px)', alignSelf: 'flex-end' }}>
      <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.62973 12L13.0397 0H10.7697L5.38971 10.09L3.5397 7.81H0.969727L4.36972 12H6.62973Z"
          fill={EXTENDED_PALETTE.$dawn.$500}
        />
      </svg>
    </Box>
  );
};

const ArrowRight = () => {
  return (
    <Box sx={{ transform: 'translateY(-0.25px) rotate(270deg)' }}>
      <svg
        width="20"
        height="20"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              d="M269.48-103.72a2.31,2.31,0,0,1-2.32-2.28,2.3,2.3,0,0,1,2.32-2.29h2.31V-106a2.3,2.3,0,0,1-2.31,2.28"
              clipRule="evenodd"
            />
          </clipPath>
          <clipPath id="clip-path-2">
            <path d="M272.21-108.71v-4.57a4.49,4.49,0,0,1,4.63,4.57Z" clipRule="evenodd" />
          </clipPath>
          <clipPath id="clip-path-3">
            <path d="M274.61-103.72a2.28,2.28,0,0,1-2.4-2.23v-2.34h4.63v4.57Z" clipRule="evenodd" />
          </clipPath>
          <clipPath id="clip-path-4">
            <path
              d="M271.79-111a2.3,2.3,0,0,1-2.32,2.29,2.29,2.29,0,0,1-2.31-2.29,2.29,2.29,0,0,1,2.31-2.28,2.3,2.3,0,0,1,2.32,2.28"
              clipRule="evenodd"
            />
          </clipPath>
        </defs>
        <g id="chevron-expanded">
          <polygon points="19 7 12 14 5 7 5 10.83 12 17.83 19 10.83 19 7" />
        </g>
      </svg>
    </Box>
  );
};

export type MenuItemProps = MuiMenuItemProps & {
  id: string;
  icon?: ReactElement;
  label: string | ReactNode;
  isHeading?: boolean;
  isNested?: boolean;
  isLink?: boolean;
  hidden?: boolean;
  explainerText?: string;
  nestedGroup?: MenuItemGroup[];
  renderNestedMenu?: (renderProps: React.PropsWithChildren<NestedMenuProps>) => React.ReactNode;
  href?: string;
  endIcon?: ReactElement | null;
  'data-test-id'?: string;
};

const MenuItem = styled((props: MenuItemProps) => {
  const {
    hidden,
    isHeading,
    isNested,
    isLink,
    nestedGroup = [],
    explainerText,
    onClick,
    href,
    renderNestedMenu,
    endIcon = null,
    ...rest
  } = props;

  if (hidden) return null;

  if (isHeading) {
    return (
      <MuiMenuItem
        sx={{
          '&:hover': {
            backgroundColor: 'common.white',
            cursor: 'default',
          },
        }}
      >
        <ListItemText primaryTypographyProps={{ variant: 'caption' }}>{props.label}</ListItemText>
        {endIcon}
      </MuiMenuItem>
    );
  }

  if (isNested) {
    return (
      <NestedMenuItem nestedGroup={nestedGroup} renderNestedMenu={renderNestedMenu} {...rest} />
    );
  }

  if (isLink) {
    return (
      <Link href={href} underline="none">
        <MuiMenuItem onClick={onClick} {...rest}>
          {props.icon && (
            <ListItemIcon sx={{ color: 'inherit', transform: 'translateY(-1.25px)' }}>
              {props.icon}
            </ListItemIcon>
          )}

          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{props.label}</ListItemText>

          {props.selected && <Tick />}
          {!props.selected && endIcon}
        </MuiMenuItem>
      </Link>
    );
  }

  return (
    <MuiMenuItem onClick={onClick} {...rest}>
      <Stack width="100%">
        <Box display="flex" alignItems="center">
          {props.icon && (
            <ListItemIcon sx={{ color: 'inherit', transform: 'translateY(-1.25px)' }}>
              {props.icon}
            </ListItemIcon>
          )}

          <ListItemText primaryTypographyProps={{ variant: 'body2', noWrap: true }}>
            {props.label}
          </ListItemText>

          {props.selected && <Tick />}
          {!props.selected && endIcon}
        </Box>

        {explainerText?.trim() && (
          <Typography
            mt={3}
            fontWeight="normal"
            color={colors.text800}
            sx={{
              fontSynthesis: 'none',
              fontVariationSettings: "'ital' 10 !important",
              textOverflow: 'clip',
              textWrap: 'wrap',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              color: '#8E9089',
            }}
          >
            {explainerText}
          </Typography>
        )}
      </Stack>
    </MuiMenuItem>
  );
})(({ theme }) => ({
  minHeight: 40,
  height: '100%',
  color: theme.palette.common.black,

  '&:hover': {
    backgroundColor: EXTENDED_PALETTE.$solar.$500,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.common.white,
  },

  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.grey[50],
  },

  '&:disabled': {
    color: colors.text800,
  },
}));

type MenuItemGroup = MenuItemProps[];
type NestedMenuProps = {
  nestedMenuState: { open: boolean; anchorEl: Element | null };
};

type MenuListProps = MuiMenuListProps & {
  groups: (MenuItemGroup | ReactElement)[];
  popup?: InjectedProps;
  renderNestedMenu?: (renderProps: React.PropsWithChildren<NestedMenuProps>) => React.ReactNode;
};

function shouldShowBottomBorder({
  isHeader,
  isLastGroup,
  isLastItemOfGroup,
}: {
  isHeader: boolean;
  isLastGroup: boolean;
  isLastItemOfGroup: boolean;
}) {
  const isNotLastItemOfGroup = !isLastItemOfGroup;

  if (isHeader) return false;
  if (isNotLastItemOfGroup) return false;
  if (isLastItemOfGroup && isLastGroup) return false;

  return true;
}

const MenuList = styled(
  forwardRef<HTMLUListElement | null, MenuListProps>((props, ref) => {
    const { renderNestedMenu, groups, popup, ...rest } = props;
    const validGroups = useMemo(
      () => groups.filter((group) => isValidElement(group) || !isEmpty(group)),
      [groups],
    );

    return (
      <MuiMenuList ref={ref} {...rest}>
        {validGroups.map((group, groupIndex) => {
          if (isValidElement(group)) {
            return [group];
          }

          return (group as MenuItemGroup).map((menuItem, menuItemIndex) => {
            const isHeader = !!menuItem.isHeading;
            const isLastGroup = groupIndex === validGroups.length - 1;
            const isLastItemOfGroup = menuItemIndex === (group as MenuItemGroup).length - 1;

            return [
              <MenuItem
                {...menuItem}
                key={menuItem.id}
                renderNestedMenu={renderNestedMenu}
                onClick={(e) => {
                  e.stopPropagation();
                  menuItem.onClick?.(e);
                  popup?.close();
                }}
              />,

              shouldShowBottomBorder({ isHeader, isLastGroup, isLastItemOfGroup }) && <Divider />,
            ];
          });
        })}
      </MuiMenuList>
    );
  }),
)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export type NestedMenuItemProps = Omit<MuiMenuItemProps, 'button'> & {
  label?: string | ReactNode;
  children?: ReactNode;
  MenuProps?: Partial<Omit<MenuProps, 'children'>>;
  nestedGroup?: MenuItemGroup[];
  icon?: ReactElement;
  renderNestedMenu?: (renderProps: React.PropsWithChildren<NestedMenuProps>) => React.ReactNode;
};

const NestedMenuItem = (props: NestedMenuItemProps) => {
  const { MenuProps, nestedGroup = [], renderNestedMenu, ...MenuItemProps } = props;
  const [open, setOpen] = useState(false);
  const menuItemRef = useRef<HTMLLIElement | null>(null);

  const handleMouseEnter = (_e: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
  };

  const handleMouseLeave = (_e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
  };

  return (
    <MuiMenuItem
      {...MenuItemProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={menuItemRef}
    >
      {props.icon && (
        <ListItemIcon sx={{ color: 'inherit', transform: 'translateY(-1.25px)' }}>
          {props.icon}
        </ListItemIcon>
      )}

      <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{props.label}</ListItemText>

      <ListItemIcon sx={{ minWidth: 50, justifyContent: 'flex-end' }}>
        <ArrowRight />
      </ListItemIcon>

      {renderNestedMenu ? (
        renderNestedMenu?.({
          children: <MenuList groups={nestedGroup} />,
          nestedMenuState: {
            open,
            anchorEl: menuItemRef.current,
          },
        })
      ) : (
        <Menu
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: 'none' }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          open={open}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setOpen(false);
          }}
          PaperProps={{
            sx: {
              pointerEvents: 'all',
              maxHeight: 400,
            },
          }}
          {...MenuProps}
        >
          <MenuList groups={nestedGroup} />
        </Menu>
      )}
    </MuiMenuItem>
  );
};

export { Menu, MenuList };
export type { MenuItemGroup };
