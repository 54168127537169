import { Box, Link, Paper, Stack, Typography, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function RestrictionLayout({ children }: PropsWithChildren) {
  const { palette } = useTheme();

  return (
    <Box sx={{ backgroundColor: palette.$grey.$100, height: '100vh' }}>
      <Stack sx={{ width: '100%', alignItems: 'center' }}>
        <Box
          component="img"
          src="https://static.cdn.drova.com/assets/logos/drova_logo.svg"
          width="207px"
          alt="drova logo"
          sx={{ my: 6 }}
        />

        <Paper
          sx={{
            width: 'clamp(0px, 510px, 510px)',
            overflow: 'hidden',
            borderRadius: '8px',
          }}
          elevation={0}
        >
          <Box
            sx={{
              height: '285px',
              backgroundColor: palette.$solar.$500,
              display: 'grid',
              placeContent: 'center',
            }}
          >
            <Box component="img" width="150px" src="/access-closed-mark.svg" alt="closed access" />
          </Box>

          <Box
            sx={{
              minHeight: '213px',
              backgroundColor: palette.$white,
              padding: 5,
              display: 'grid',
              placeContent: 'center',
            }}
          >
            {children}
          </Box>
        </Paper>

        <Typography mt={5} variant="caption">
          Read our{' '}
          <Link to="https://www.drova.com/privacy" component={RouterLink}>
            terms of use
          </Link>{' '}
          and{' '}
          <Link component={RouterLink} to="https://www.drova.com/privacy">
            privacy policy
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
}
