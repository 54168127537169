import { Box, Typography } from '@mui/material';

import * as colors from '@interface/colors/lib/common';
import { LogomarkDrova } from '@interface/icons/lib/logomark-drova';

import { useBreakpoints } from '../../hooks';
import { MenuItemGroup } from '../menu';
import { HeaderMenu } from './header-menu';

interface ProductMenuProps {
  name: string;
  menu?: MenuItemGroup[] | null;
  onClickLogo?: () => void;
}

const ProductMenu = ({ name, menu, onClickLogo }: ProductMenuProps) => {
  const [isMobileView] = useBreakpoints({ query: 'down', screenSize: 'sm' });

  let render = (
    <HeaderMenu
      id="product-menu"
      startIcon={<LogomarkDrova size="XLarge" color="white" />}
      menu={menu}
      label={
        <Typography
          variant="h6"
          fontWeight={700}
          ml={1}
          sx={{ display: { xs: 'none', md: 'inherit' } }}
        >
          {name}
        </Typography>
      }
    />
  );

  if (onClickLogo) {
    render = (
      <Box display="flex">
        <Box onClick={onClickLogo}>
          <HeaderMenu
            id="product-logo"
            startIcon={<LogomarkDrova size="XLarge" color="white" />}
            label={
              <Typography
                variant="h6"
                fontWeight={700}
                ml={1}
                sx={{ display: { xs: 'none', md: 'inherit', color: colors.earth500 } }}
              >
                {name}
              </Typography>
            }
            ButtonProps={{
              sx: {
                px: 0,
                ...(isMobileView && {
                  paddingLeft: '9px',
                  minWidth: 'auto !important',
                }),
              },
            }}
          />
        </Box>
        <HeaderMenu
          id="product-switcher"
          menu={menu}
          label=""
          ButtonProps={{
            sx: {
              minWidth: 'unset',
              padding: 0,
              '.MuiButton-endIcon': {
                marginLeft: 0,
              },
            },
          }}
        />
      </Box>
    );
  }

  return render;
};

export { ProductMenu };
