import {
  PaletteOptions,
  Theme,
  ThemeOptions,
  TypographyVariantsOptions,
  createTheme,
} from '@mui/material/styles';

import {
  berry,
  black,
  citrus,
  dawn,
  functionalGreen500,
  functionalRed600,
  lemon,
  mandarine,
  neutral100,
  neutral200,
  neutral300,
  neutral400,
  neutral500,
  neutral600,
  sunrise,
  textBase,
  textLight,
  textWhite,
  white,
} from '@interface/colors/lib/common';
import { brand500, brand550, brand600 } from '@interface/colors/lib/legacy';

import { ThemeMode } from '../types';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    name: string;
  }
}

function createPalette(mode: ThemeMode): PaletteOptions {
  return {
    primary: {
      main: brand500,
      dark: brand550,
    },
    secondary: {
      main: brand600,
      dark: black,
    },
    error: {
      main: functionalRed600,
      contrastText: white,
    },
    warning: {
      main: citrus,
      light: mandarine,
      contrastText: textBase,
    },
    info: {
      main: dawn,
      light: berry,
      contrastText: textBase,
    },
    success: {
      main: functionalGreen500,
      contrastText: white,
    },
    mode,
    common: {
      black: black,
      white: white,
    },
    text: {
      primary: mode === 'light' ? textBase : textWhite,
      secondary: mode === 'light' ? textLight : textWhite,
    },
    grey: {
      100: neutral100,
      200: neutral200,
      300: neutral300,
      400: neutral400,
      500: neutral500,
      600: neutral600,
    },
    divider: neutral200,
    // background: {
    //   default: '#FAFAFC', // review this with Chantelle
    // },
  };
}

type Args = {
  name: string;
  mode: ThemeMode;
};

function createTypography(): TypographyVariantsOptions {
  return {
    fontFamily: '"Aktiv Grotesk", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 'normal',
      fontSize: '96px',
      letterSpacing: '-0.2px',
    },
    h2: {
      fontWeight: 'normal',
      fontSize: '60px',
      letterSpacing: '-0.2px',
    },
    h3: {
      fontWeight: 'normal',
      fontSize: '40px',
      letterSpacing: '-0.2px',
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '32px',
      letterSpacing: '-0.2px',
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '24px',
      letterSpacing: '-0.2px',
    },
    h6: {
      fontWeight: 'normal',
      fontSize: '20px',
      letterSpacing: '-0.2px',
    },
    subtitle1: {
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '-0.2px',
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: '12px',
      letterSpacing: '0.34px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '-0.2px',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.34px',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '12px',
      letterSpacing: '0.34px',
    },
    overline: {
      fontWeight: 'normal',
      fontSize: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.34px',
    },
  };
}

export function createAceTheme({ name, mode }: Args): Theme {
  const themeOptions: ThemeOptions = {
    name,
    typography: createTypography(),
    palette: createPalette(mode),
    spacing: [2, 4, 8, 12, 16, 24, 32, 64],
    shape: {
      borderRadius: 4,
    },
    shadows: [
      'none',
      'none', // 'rgb(0 0 0 / 5%) 0px 2px 4px 0px, rgb(0 0 0 / 10%) 0px 4px 8px -4px',
      'none', // rgb(0 0 0 / 10%) 0px 8px 16px -4px',
      'none', // 'rgb(0 0 0 / 5%) 0px 2px 4px 0px, rgb(0 0 0 / 10%) 0px 16px 32px -4px',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: neutral300,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: neutral400,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: neutral400,
              // boxShadow:
              //   'inset 0 0 0 1px transparent, 0 0 0 4px rgb(167 167 170 / 30%)',
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            border: `1px solid ${neutral200}`,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          sizeLarge: {
            fontSize: '16px',
            letterSpacing: '-0.2px',
          },
          sizeMedium: {
            fontSize: '12px',
            letterSpacing: '0.34px',
          },
          sizeSmall: {
            fontSize: '12px',
            letterSpacing: '0.34px',
          },
          outlined: {
            backgroundColor: white,
            '&:hover': {
              backgroundColor: brand500,
              color: white,
            },
          },
        },
        variants: [
          {
            props: { variant: 'contained', color: 'warning' },
            style: {
              backgroundColor: sunrise,
              color: black,
              '&:hover': {
                backgroundColor: lemon,
              },
            },
          },
        ],
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: '2px solid #ebebed',
            '&:hover': {
              borderColor: '#B4BABB',
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1280,
        xl: 1400,
      },
    },
  };
  return createTheme(themeOptions);
}
